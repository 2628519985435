<template>
    <div>
        <v-row>

            <v-col cols="12" lg="4">
                <v-card color="gifty" dark flat>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <h3 class="mb-2 white--text">DZD</h3>
                                <v-skeleton-loader v-if="loading" type="text"/>
                                <div v-else class="white--text font-weight-medium">
                                    {{ CurrencyFormatting(statistics.current_day) }}
                                </div>
                            </v-col>
                            <v-col class="text-right">
                                <v-avatar color="white">
                                    <v-icon color="gifty">mdi-transfer</v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="8">
                                <span class="white--text">Montant à verser</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" lg="4">
                <v-card :loading="monthLoading" outlined>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <h3 class="mb-2">DZD</h3>
                                <v-skeleton-loader v-if="loading || monthLoading" type="text"/>
                                <div v-else class="font-weight-medium">
                                    {{ CurrencyFormatting(statistics.current_month) }}
                                </div>
                            </v-col>
                            <v-col class="text-right">
                                <v-avatar color="gifty">
                                    <v-icon dark>mdi-calendar-month</v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="10">
                                <div>
                                    Total mois de
                                    {{
                                    statistics.this_month ? months.find(el => el.value === statistics.this_month).name : ''
                                    }}
                                </div>
                            </v-col>
                            <v-col class="text-right" cols="2">
                                <v-menu bottom
                                        right transition="slide-x-transition"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <strong v-bind="attrs" v-on="on">
                                            <v-icon color="gifty" dense>mdi-chevron-double-down</v-icon>
                                        </strong>
                                    </template>

                                    <v-list-item-group v-model="statistics.this_month"
                                                       active-class="gifty white--text"
                                                       mandatory>
                                        <v-list>
                                            <v-list-item v-for="(month, i) in months"
                                                         :key="i"
                                                         :value="month.value"
                                                         @click="getRecoveriesAmountByMonth(month.value)">
                                                <v-list-item-title>{{ month.name }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-list-item-group>
                                </v-menu>

                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" lg="4">
                <v-card :loading="yearLoading" outlined>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <h3 class="mb-2">DZD</h3>
                                <v-skeleton-loader v-if="loading || yearLoading" type="text"/>
                                <div v-else class="font-weight-medium">
                                    {{ CurrencyFormatting(statistics.current_year) }}
                                </div>
                            </v-col>
                            <v-col class="text-right">
                                <v-avatar color="gifty">
                                    <v-icon dark>mdi-calendar-check</v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="10">
                                <div>Total année {{ statistics.this_year }}</div>
                            </v-col>
                            <v-col class="text-right" cols="2">
                                <v-menu bottom
                                        right transition="slide-x-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="gifty"
                                                dense
                                                v-bind="attrs" v-on="on">mdi-chevron-double-down
                                        </v-icon>
                                    </template>

                                    <v-list-item-group v-model="statistics.this_year"
                                                       active-class="gifty white--text"
                                                       mandatory>
                                        <v-list>
                                            <v-list-item v-for="(year) in years"
                                                         :key="year"
                                                         :value="year"
                                                         @click="getRecoveriesAmountByYear(year)">
                                                <v-list-item-title>{{ year }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-list-item-group>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" lg="4">
                <v-card outlined>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <h3 class="mb-2">DZD</h3>
                                <v-skeleton-loader v-if="loading" type="text"/>
                                <div v-else class="font-weight-medium">
                                    {{ CurrencyFormatting(statistics.today_gap) }}
                                </div>
                            </v-col>
                            <v-col class="text-right">
                                <v-avatar color="primary">
                                    <v-icon dark>mdi-calendar-today</v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12">
                                <span>Écart aujourd'hui</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" lg="4">
                <v-card outlined>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <h3 class="mb-2">DZD</h3>
                                <v-skeleton-loader v-if="loading" type="text"/>
                                <div v-else class="font-weight-medium">
                                    {{ CurrencyFormatting(statistics.all_gap) }}
                                </div>
                            </v-col>
                            <v-col class="text-right">
                                <v-avatar color="primary">
                                    <v-icon dark>mdi-arrow-up</v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12">
                                <span>Reste à récupérer</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" lg="4">
                <v-card outlined>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <h3 class="mb-2">DZD</h3>
                                <v-skeleton-loader v-if="loading" type="text"/>
                                <div v-else
                                     class="font-weight-medium">
                                    {{ CurrencyFormatting(statistics.recovered_amount_total) }}
                                </div>
                            </v-col>
                            <v-col class="text-right">
                                <v-avatar color="primary">
                                    <v-icon dark>mdi-check-circle</v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12">
                                <span>Montant total récupéré</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>
    </div>
</template>

<script>
import {HTTP} from "@/http-common";
import moment from "moment/moment";

export default {
    props: ['statistics', 'loading', 'recuperatorId'],
    data() {
        return {
            monthLoading: false,
            yearLoading: false,

            month: moment().format('MM'),
            year: moment().format('YYYY'),
            currentMonth: moment().format('MM'),
            currentYear: moment().format('YYYY')
        }
    },
    methods: {
        getRecoveriesAmountByMonth(month) {
            this.monthLoading = true
            let data = {
                recuperator_id: this.recuperatorId,
                month: month,
            }
            HTTP.get('/v1/recoveries/amount-month', {
                params: data
            }).then(res => {
                this.monthLoading = false
                this.statistics.current_month = res.data.data.amount
            }).catch(err => {
                this.monthLoading = false
                console.log(err)
            })
        },
        getRecoveriesAmountByYear(year) {
            this.yearLoading = true
            let data = {
                recuperator_id: this.recuperatorId,
                year: year,
            }
            HTTP.get('/v1/recoveries/amount-year', {
                params: data
            }).then(res => {
                this.yearLoading = false
                this.statistics.current_year = res.data.data.amount
            }).catch(err => {
                this.yearLoading = false
                console.log(err)
            })
        },
        setMonth(month) {
            return this.months.find(el => el.value === month).name
        },
    },
    computed: {
        months() {
            return [
                {name: 'Janvier', value: "01"},
                {name: 'Février', value: "02"},
                {name: 'Mars', value: "03"},
                {name: 'Avril', value: "04"},
                {name: 'Mai', value: "05"},
                {name: 'Juin', value: "06"},
                {name: 'Juillet', value: "07"},
                {name: 'Août', value: "08"},
                {name: 'Septembre', value: "09"},
                {name: 'Octobre', value: "10"},
                {name: 'Novombre', value: "11"},
                {name: 'Décembre', value: "12"}
            ]
        },
        years() {
            return ["2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"]
        },
    }
}
</script>

<style scoped>

</style>